
import { ChallengeShortInfo } from "@/interfaces/challenge";
import { Component, Prop, Vue } from "vue-property-decorator";

interface Props {
  challenge: ChallengeShortInfo;
}

@Component({})
export default class PurchaseChallenge extends Vue {
  @Prop({})
  props!: Props;

  challenge!: ChallengeShortInfo;

  created() {
    this.challenge = this.props.challenge;
  }

  goToCheckout() {
    this.$store.commit("setPayment", this.challenge);
    this.$router.push("/payment-challenge");
  }
}
