
import { Component, Vue } from "vue-property-decorator";
import PurchaseChallenge from "@/components/challenges/PurchaseChallenge.vue";
import MarkAsCompleted from "@/components/challenges/MarkAsCompleted.vue";
import {
  ChallengeEpisode,
  ChallengeSeries,
  ChallengeShortInfo,
} from "@/interfaces/challenge";
import { get } from "@/api";

@Component({
  components: {
    PurchaseChallenge,
    MarkAsCompleted,
  },
})
export default class DayChallengePreview extends Vue {
  challengeSeriesId = Number(this.$route.params.challengeId);

  challenges!: ChallengeSeries;
  loaded = false;
  current_index = 0;
  changing: null | boolean = true;
  subscription = false;
  videos_amount = 0;
  completedEpisodes: Record<number, boolean> = {};
  navbarCompletedKey = 0;

  mobileMenuVisible = false;

  challengePrice!: ChallengeShortInfo | undefined;

  created() {
    this.getChallenges(this.challengeSeriesId);
    this.getChallengePrice();
  }

  async getChallenges(id: number) {
    const { data } = await get.challenges(id);
    this.challenges = data;
    this.loaded = true;
    this.videos_amount = this.challenges.available_episodes[
      this.current_index
    ].videos.length;
    if (this.challenges.purchased) {
      this.subscription = true;
    } else {
      this.subscription = false;
    }
    this.generateTempCompleted();
    this.challenges.available_episodes.sort(
      (elem1: ChallengeEpisode, elem2: ChallengeEpisode) => {
        if (elem1.order > elem2.order) {
          return 1;
        }
        if (elem1.order < elem2.order) {
          return -1;
        }
        return 0;
      }
    );
    console.log(data);
  }
  async getChallengePrice() {
    try {
      const { data } = await get.challengeShortInfo();
      this.findPickedChallenge(data);
    } catch (error) {
      console.log(error);
    }
  }

  findPickedChallenge(challenges: ChallengeShortInfo[]) {
    // Function used to gather data about picked challenge if user want to buy it
    this.challengePrice = challenges.find(
      (challenge) => challenge.id == this.challengeSeriesId
    );
  }

  changeContent(id: number) {
    if (this.subscription) {
      this.changing = false;
      setTimeout(() => {
        this.changing = true;
      }, 1000);

      this.current_index = id;
    } else {
      this.openModal();
    }
  }

  changeIndex(id: number) {
    if (this.subscription) {
      const max_index = this.challenges.available_episodes.length;

      if (id === -1 && this.current_index > 0) {
        this.current_index += id;
        this.changeContent(this.current_index);
      }
      if (id === 1 && this.current_index < max_index - 1) {
        this.current_index += id;
        this.changeContent(this.current_index);
      }
    } else {
      this.openModal();
    }
  }

  generateTempCompleted() {
    this.challenges.available_episodes.forEach((episode) => {
      this.completedEpisodes[episode.id] = episode.completed;
    });
  }

  markEpisodeAsCompleted(completedEpisodeId: number) {
    this.completedEpisodes[completedEpisodeId] = true;
    this.navbarCompletedKey += 1;
  }

  getFilename(instance: string) {
    const result = instance.split("/");
    return result[result.length - 1];
  }

  openModal() {
    if (this.challenges.waiting) {
      this.$openModal({
        component: "PaymentConfirmation",
        closeButton: true,
        props: {},
      });
    } else {
      this.$openModal({
        component: "PurchaseChallenge",
        closeButton: true,
        props: {
          challenge: this.challengePrice,
        },
      });
    }
  }
}
