var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"wrapper"},[_c('nav',[_c('div',[_c('p',{on:{"click":function($event){_vm.mobileMenuVisible = !_vm.mobileMenuVisible}}},[_vm._v(" check your progress ")]),_c('i',{staticClass:"far fa-chevron-down",class:{ visible: _vm.mobileMenuVisible }})]),_c('ul',{class:{ visible: _vm.mobileMenuVisible }},_vm._l((_vm.challenges.available_episodes),function(challenge,index){return _c('li',{key:index,ref:"radio",refInFor:true,class:{ active: index === _vm.current_index },on:{"click":function($event){return _vm.changeContent(index)}}},[(index > 0 && !_vm.subscription)?_c('i',{staticClass:"fad fa-lock"}):_vm._e(),(_vm.completedEpisodes[index + 1] && _vm.subscription)?_c('i',{key:_vm.navbarCompletedKey,staticClass:"far fa-check"}):_vm._e(),_c('p',[_vm._v(_vm._s(challenge.full_day_name))])])}),0)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"video-wrapper"},[_c('div',{staticClass:"video"},[_c('h2',[_vm._v(_vm._s(_vm.challenges.available_episodes[_vm.current_index].topic))]),_c('transition',{attrs:{"name":"fade"}},[(_vm.changing)?_c('iframe',{attrs:{"allow":"autoplay; fullscreen","src":_vm.challenges.available_episodes[_vm.current_index].videos[0].url,"frameborder":"0"}}):_vm._e()])],1),_c('button',{class:{ disabled: _vm.current_index === 0 },on:{"click":function($event){return _vm.changeIndex(-1, $event.target)}}},[_c('i',{staticClass:"far fa-chevron-left"})]),_c('button',{class:{
          disabled:
            _vm.current_index === _vm.challenges.available_episodes.length - 1,
        },on:{"click":function($event){return _vm.changeIndex(1, $event.target)}}},[_c('i',{staticClass:"far fa-chevron-right"})])]),_c('div',{staticClass:"text-content-wrapper"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.challenges.available_episodes[_vm.current_index].videos[0]
            .rendered_content
        )}})]),_c('div',{staticClass:"text-content-wrapper"},_vm._l((_vm.challenges.available_episodes[_vm.current_index]
          .videos),function(video,index){return _c('div',{key:video.order},[_c('div',{staticClass:"video-additional"},[(_vm.changing && index > 0)?_c('iframe',{attrs:{"allow":"fullscreen","src":video.url,"frameborder":"0"}}):_vm._e()]),(index > 0)?_c('div',{domProps:{"innerHTML":_vm._s(video.rendered_content)}}):_vm._e()])}),0),(_vm.challenges.purchased)?_c('MarkAsCompleted',{key:_vm.current_index,staticClass:"markAsCompleted",attrs:{"completed_episodes":_vm.completedEpisodes,"challegne_id":_vm.challenges.id,"episode_id":_vm.challenges.available_episodes[_vm.current_index].id},on:{"mark-episode-completed":_vm.markEpisodeAsCompleted}}):_vm._e(),(_vm.challenges.available_episodes[_vm.current_index].resources.length > 0)?_c('div',{staticClass:"resources"},[_c('h2',[_vm._v(_vm._s(_vm.$t("challenges.resources")))]),_c('div',{staticClass:"button-wrapper"},_vm._l((_vm.challenges.available_episodes[_vm.current_index]
            .resources),function(resource){return _c('button',{key:resource.id},[_c('a',{attrs:{"target":"__blank","href":resource}},[_c('i',{staticClass:"fas fa-download"}),_vm._v(" "+_vm._s(_vm.getFilename(resource))+" ")])])}),0)]):_vm._e()],1)]):_c('div',{staticClass:"spinner"},[_c('spinner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }