
import { 
  Component, 
  Prop, 
  Vue } 
from 'vue-property-decorator';
import { post } from "@/api"

@Component({})
export default class MarkAsCompleted extends Vue {
  @Prop({ default: -1 }) 
  episode_id!: number;

  @Prop({ default: -1 })
  challegne_id!: number;

  @Prop({})
  completed_episodes!: Record<number, boolean>;

  isLoggedIn = this.$store.state.isLoggedIn
  completed = false

  async mark_as_completed() {
    try {
      await post.completeEpisode(this.challegne_id, this.episode_id)
      this.$emit('mark-episode-completed', this.episode_id)
      this.completed = true
    } catch (error) {
      console.log(error)
    }

  }
}
